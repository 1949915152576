
export default
	inject: textColor: default: ''

	props:
		section: Object

	computed:
		computedTextColor: -> @textColor || @section.textColor


