import { render, staticRenderFns } from "./fancy-card.vue?vue&type=template&id=647eda44&scoped=true&lang=pug"
import script from "./fancy-card.vue?vue&type=script&lang=coffee"
export * from "./fancy-card.vue?vue&type=script&lang=coffee"
import style0 from "./fancy-card.vue?vue&type=style&index=0&id=647eda44&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647eda44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Squircle: require('/opt/build/repo/site/components/globals/squircle/index.vue').default})
