
import JobCard from '/components/blocks/fancy-cards/job-card'
import FancyCard from '/components/blocks/fancy-cards/fancy-card'
export default
	props:
		card: Object | Array
		index: Number

	computed:
		classes: -> [
			"large-text-#{@computedCard.largeTextSize || 'm'}" if @cardType == 'stats'
			"content-align-#{@computedCard.cardContentAlignment || 'default'}" if @cardType == 'link'
			"large-carousel" if @isCarouselLarge
		]


		cardType: -> switch @computedCard.__typename
			when "blocks_nextCardCareers_BlockType" then "careers"
			when "blocks_nextCardCarousel_BlockType" then "carousel"
			when "blocks_nextCardStatText_BlockType" then "stats"
			when "blocks_nextCardLink_BlockType" then "link"

		isCarouselLarge: -> @computedCard.__typename == "blocks_nextCardCarousel_BlockType" and @index == 5

		# Get the card object
		computedCard: -> if Array.isArray @card then @card[0] else card

		# Using either job card or fancy card
		component: -> switch @computedCard.__typename
			when "blocks_nextCardCareers_BlockType" then JobCard
			else FancyCard

		# Create the props object for the FancyCard and JobCard components
		componentProps: -> card: switch @computedCard.__typename
			# Just pass the props if job card
			when "blocks_nextCardCareers_BlockType" then @computedCard

			# Make carousel slides
			when "blocks_nextCardCarousel_BlockType"
				autoPlay: @computedCard.autoPlay
				slides: @computedCard.slides?.map (slide) =>
					url: slide.url
					containImage: false
					buttons: slide.buttons
					size: if @index == 5 then "large" else "small"
					image: slide.image
					video: slide.video
					sections: [{...slide, buttons: undefined}, {buttons: slide.buttons}]
					modalVideo: slide.libraryVideo

			# Divide stat and text cards into appropriate sections
			when "blocks_nextCardStatText_BlockType"
				slides: [{
					sections:
						[
							{
								largeText: @computedCard.largeText
								textColor: @computedCard.textColor
							}
							{
								copy: @computedCard.copy
								textColor: @computedCard.eyebrowColor
							}

						]
					backgroundColor: @computedCard.backgroundColor
				}]

			# Divide link card into appropriate sections
			when "blocks_nextCardLink_BlockType"
				size: if @index in [0, 5] then "large" else "small"
				slides: [{
					backgroundColor: @computedCard.backgroundColor
					containImage: !@computedCard.fullBleedImage
					image: @computedCard.image
					size: if @index in [0, 5] then "large" else "small"
					sections:
						[
							{
								headline: @computedCard.headline
								textColor: @computedCard.textColor
								eyebrow: @computedCard.eyebrow
								eyebrowColor: @computedCard.eyebrowColor
							}
							{
								buttons: @computedCard.buttons
							}
						]
				}]


